import React, { Component } from "react"
import StyledCustomers from "./styled/StyledCustomers"

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {

      interval: 3000,
      currentImageIndex1: 0,
      currentImageIndex2: 0,
      currentImageIndex3: 0,
      currentImageIndex4: 0,
      currentImageIndex5: 0,
      currentImageIndex6: 0,
      currentImageIndex7: 0,

      images: {
            einzelhandel: [
                   "/img/customer-logos/deichmann.png",
                   "/img/customer-logos/fressnapf.svg",
                   "/img/customer-logos/hardeck.png",
                   "/img/customer-logos/ernstings.png",
                   "/img/customer-logos/tedi.png",
                   "/img/customer-logos/superbiomarkt.jpg",
                   "/img/customer-logos/aldi_nord.png",
                   "/img/customer-logos/snipes.svg",
                   "/img/customer-logos/mueller.png"
                ],
                hotellerie: [
                   "/img/customer-logos/courtyard.png",
                   "/img/customer-logos/moxy.png",
                   "/img/customer-logos/residence_inn.jpg",
                   "/img/customer-logos/h-hotels.svg"
                ],
                gastronomie: [
                   "/img/customer-logos/burgerking.svg"

                ],
                industrieUndTechnology: [
                   "/img/customer-logos/air_products.png",
                   "/img/customer-logos/crafty.png",
                   "/img/customer-logos/ebm.png"
                ],
                bauwesenUndInfrastruktur: [
                   "/img/customer-logos/aalberts.png",
                   "/img/customer-logos/tmd_friction.png",
                   "/img/customer-logos/strabag.svg",

                ],
                logistikUndTransport: [

                   "/img/customer-logos/nox.jpg"
                ],
                freizeitUndFitness: [
                   "/img/customer-logos/fitx.png",
                   "/img/customer-logos/fitx.png",
                   "/img/customer-logos/fitx.png",
                ],
            },


      paused: false,
    };

  }
  componentDidMount() {
      const categories = ['einzelhandel', 'hotellerie', 'gastronomie', 'industrieUndTechnology', 'bauwesenUndInfrastruktur', 'logistikUndTransport', 'freizeitUndFitness'];
      let currentCategoryIndex = 0;

      this.autoScrollInterval = setInterval((paused) => {
        if (!this.state.paused) {
          this.goToNext(categories[currentCategoryIndex]);
          currentCategoryIndex = (currentCategoryIndex + 1) % categories.length; // Cycle through categories
        }
      }, this.state.interval);
    }


  componentWillUnmount() {
    clearInterval(this.autoScrollInterval);
  }

  goToPrev = (category) => {
      this.setState({paused: true})
      const categoryMap = {
        einzelhandel: 'currentImageIndex1',
        hotellerie: 'currentImageIndex2',
        gastronomie: 'currentImageIndex3',
        industrieUndTechnology: 'currentImageIndex4',
        bauwesenUndInfrastruktur: 'currentImageIndex5',
        logistikUndTransport: 'currentImageIndex6',
        freizeitUndFitness: 'currentImageIndex7'
        // Add mappings for other categories
      };
      const indexKey = categoryMap[category];
      if (!indexKey) {
        console.error(`Category ${category} does not exist.`);
        return;
      }

      this.setState(prevState => {
        const categoryImages = prevState.images[category];
        const currentIndex = prevState[indexKey];
        const newIndex = currentIndex === 0 ? categoryImages.length - 1 : currentIndex - 1;
        return { [indexKey]: newIndex };
      });
    }

    goToNext = (category) => {
      const categoryMap = {
        einzelhandel: 'currentImageIndex1',
        hotellerie: 'currentImageIndex2',
        einzelhandel: 'currentImageIndex1',
        hotellerie: 'currentImageIndex2',
        gastronomie: 'currentImageIndex3',
        industrieUndTechnology: 'currentImageIndex4',
        bauwesenUndInfrastruktur: 'currentImageIndex5',
        logistikUndTransport: 'currentImageIndex6',
        freizeitUndFitness: 'currentImageIndex7'


          // Add mappings for other categories
      };
      const indexKey = categoryMap[category];
      if (!indexKey) {
        console.error(`Category ${category} does not exist.`);
        return;
      }

      this.setState(prevState => {
        const categoryImages = prevState.images[category];
        const currentIndex = prevState[indexKey];
        const newIndex = (currentIndex + 1) % categoryImages.length;
        return { [indexKey]: newIndex };
      });
    }
    render() {
          const imageSrcEinzelhandel = this.state.images.einzelhandel[this.state.currentImageIndex1];
          const imageSrcHotellerie = this.state.images.hotellerie[this.state.currentImageIndex2];
          const imageSrcGastronomie = this.state.images.gastronomie[this.state.currentImageIndex3];
          const imageSrcIndustrieUndTechnology = this.state.images.industrieUndTechnology[this.state.currentImageIndex4];
          const imageSrcBauwesenUndInfrastruktur = this.state.images.bauwesenUndInfrastruktur[this.state.currentImageIndex5];
          const imageSrcLogistikUndTransport = this.state.images.logistikUndTransport[this.state.currentImageIndex6];
          const imageSrcFreizeitUndFitness = this.state.images.freizeitUndFitness[this.state.currentImageIndex7];
        return <StyledCustomers>

<h3>Sie sind in guter Gesellschaft.</h3>
 <h2>Auszug unserer Kunden</h2>
            <div className="container">
                <div className="innerContainer">
                  <h4 className="title">Logistik & Transport</h4>
                    <div className="logos">
                      <img src="/img/customer-logos/dhl.png" />
                      <img src="/img/customer-logos/nox.jpg" />
                    </div>
                </div>
                <div className="innerContainer">
                    <h4 className="title">Gastronomie & Lebensmittel</h4>
                    <div className="logos">
                        <img src="/img/customer-logos/burgerking.svg" />
                        <img src="/img/customer-logos/superbiomarkt.jpg" />
                    </div>
                </div>
                <div className="innerContainer">
                    <h4 className="title">Industrie & Technologie</h4>
                    <div className="logos">
                        <img src="/img/customer-logos/air_products.png" />
                        <img src="/img/customer-logos/crafty.png" />
                        <img src="/img/customer-logos/ebm.png" />
                    </div>
                </div>
                <div className="innerContainer">
                  <h4 className="title">Hotellerie</h4>
                    <div className="logos">
                    <img src="/img/customer-logos/courtyard.png" />
                    <img src="/img/customer-logos/moxy.png" />
                    <img src="/img/customer-logos/residence_inn.jpg" />
                    <img src="/img/customer-logos/h-hotels.svg" />
                    <img src="/img/customer-logos/lindner_hotels.png" />
                    <img src="/img/customer-logos/holiday_inn.png" />
                    </div>
                </div>
                <div className="innerContainer">
                    <h4 className="title">Einzelhandel</h4>
                    <div className="logos">
                        <img src="/img/customer-logos/deichmann.png" />
                        <img src="/img/customer-logos/fressnapf.svg" />
                        <img src="/img/customer-logos/ernstings.png" />
                        <img src="/img/customer-logos/tedi.png" />
                        {/*<img src="/img/customer-logos/bofrost.png" />*/}
                        <img src="/img/customer-logos/aldi_nord.png" />
                        {/*<img src="/img/customer-logos/snipes.svg" />*/}
                        <img src="/img/customer-logos/mueller.png" />
                    </div>
                </div>
                <div className="innerContainer">
                  <h4 className="title">Bauwesen & Infrastruktur</h4>
                    <div className="logos">
                       <img src="/img/customer-logos/aalberts.png" />
                       <img src="/img/customer-logos/tmd_friction.png" />
                       <img src="/img/customer-logos/strabag.svg" />
                    </div>
                </div>
                <div className="innerContainer">
                    <h4 className="title">Freizeit & Gesundheit</h4>
                    <div className="logos">
                        <img src="/img/customer-logos/fitx.png" />
                        <img src="/img/customer-logos/hardeck.png" />
                        <img src="/img/customer-logos/median.png" />
                    </div>
                </div>

    </div>


   {/*<div className="newContainer" style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
        <div  style={{display: "flex", padding: "64px", gridGap:"64px"}}>
          <div className="SlideContainer" style={{display: "flex", justifyContent:"center", textAlign:"center"}}>
              <div className="innerContainer">
                    <h4 className="title">Einzelhandel</h4>
                    <div style={{width:"300px", height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>
                          <button className="buttonStyled" onClick={() => this.goToPrev('einzelhandel')} >
                                <i className="fas fa-arrow-left" />
                          </button>
                    <div className="logos" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", width:"100%", border: "1px black ", padding:"0 12px 0 12px"}}>
                        <img src={imageSrcEinzelhandel} alt="Slide" style={{height:"60%", width:"100%", filter:"none", opacity:"1", objectFit:"contain", userSelect:"none"}} />
                    </div>
                          <button className="buttonStyled" onClick={() => this.goToNext('einzelhandel')}>
                                <i className="fas fa-arrow-right" />
                          </button>
                    </div>
                </div>
          </div>
          <div className="SlideContainer" style={{display: "flex", justifyContent:"center", textAlign:"center"}}>
              <div className="innerContainer">
                    <h4 className="title">Hotellerie</h4>
                    <div style={{width:"300px", height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>
                          <button className="buttonStyled" onClick={() => this.goToPrev('hotellerie')} >
                                <i className="fas fa-arrow-left" />
                          </button>
                    <div className="logos" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", width:"100%", border: "1px black ", padding:"0 12px 0 12px"}}>
                        <img src={imageSrcHotellerie} alt="Slide" style={{height:"60%", width:"100%", filter:"none", opacity:"1", objectFit:"contain"}} />
                    </div>
                          <button className="buttonStyled" onClick={() => this.goToNext('hotellerie')} >
                                <i className="fas fa-arrow-right" />
                          </button>
                    </div>
                </div>
          </div>
          <div className="SlideContainer" style={{display: "flex", justifyContent:"center", textAlign:"center"}}>
              <div className="innerContainer">
                    <h4 className="title">Gastronomie</h4>
                    <div style={{width:"300px", height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>
                          <button className="buttonStyled" onClick={() => this.goToPrev('gastronomie')} >
                                <i className="fas fa-arrow-left" />
                          </button>
                    <div className="logos" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", width:"100%", border: "1px black ", padding:"0 12px 0 12px"}}>
                        <img src={imageSrcGastronomie} alt="Slide" style={{height:"60%", width:"100%", filter:"none", opacity:"1", objectFit:"contain"}} />
                    </div>
                          <button className="buttonStyled" onClick={() => this.goToNext('gastronomie')} >
                                <i className="fas fa-arrow-right" />
                          </button>
                    </div>
                </div>
          </div>
        </div>
        <div style={{display:"flex", padding: "64px", gridGap:"64px"}}>
          <div className="SlideContainer" style={{display: "flex", justifyContent:"center", textAlign:"center"}}>
              <div className="innerContainer">
                    <h4 className="title">Industrie und Technologie</h4>
                    <div style={{width:"300px", height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>
                          <button className="buttonStyled" onClick={() => this.goToPrev('industrieUndTechnology')} >
                                <i className="fas fa-arrow-left" />
                          </button>
                    <div className="logos" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", width:"100%", border: "1px black ", padding:"0 12px 0 12px"}}>
                        <img src={imageSrcIndustrieUndTechnology} alt="Slide" style={{height:"60%", width:"100%", filter:"none", opacity:"1", objectFit:"contain"}} />
                    </div>
                          <button className="buttonStyled" onClick={() => this.goToNext('industrieUndTechnology')} >
                                <i className="fas fa-arrow-right" />
                          </button>
                    </div>
                </div>
          </div>
          <div className="SlideContainer" style={{display: "flex", justifyContent:"center", textAlign:"center"}}>
              <div className="innerContainer">
                    <h4 className="title">Bauwesen und Infrastruktur</h4>
                    <div style={{width:"300px", height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>
                          <button className="buttonStyled" onClick={() => this.goToPrev('bauwesenUndInfrastruktur')} >
                                <i className="fas fa-arrow-left" />
                          </button>
                    <div className="logos" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", width:"100%", border: "1px black ", padding:"0 12px 0 12px"}}>
                        <img src={imageSrcBauwesenUndInfrastruktur} alt="Slide" style={{height:"60%", width:"100%", filter:"none", opacity:"1", objectFit:"contain"}} />
                    </div>
                          <button className="buttonStyled" onClick={() => this.goToNext('bauwesenUndInfrastruktur')}>
                                <i className="fas fa-arrow-right" />
                          </button>
                    </div>
                </div>
          </div>
          <div className="SlideContainer" style={{display: "flex", justifyContent:"center", textAlign:"center"}}>
              <div className="innerContainer">
                    <h4 className="title">Logistik und Transport</h4>
                    <div style={{width:"300px", height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>
                          <button className="buttonStyled" onClick={() => this.goToPrev('logistikUndTransport')} >
                                <i className="fas fa-arrow-left" />
                          </button>
                    <div className="logos" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", width:"100%", border: "1px black ", padding:"0 12px 0 12px"}}>
                        <img src={imageSrcLogistikUndTransport} alt="Slide" style={{height:"60%", width:"100%", filter:"none", opacity:"1", objectFit:"contain"}} />
                    </div>
                          <button className="buttonStyled" onClick={() => this.goToNext('logistikUndTransport')} >
                                <i className="fas fa-arrow-right" />
                          </button>
                    </div>
                </div>
          </div>
          <div className="SlideContainer" style={{display: "flex", justifyContent:"center", textAlign:"center"}}>
              <div className="innerContainer">
                    <h4 className="title">Freizeit und Fitness</h4>
                    <div style={{width:"300px", height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>
                          <button className="buttonStyled" onClick={() => this.goToPrev('freizeitUndFitness')} >
                                <i className="fas fa-arrow-left" />
                          </button>
                    <div className="logos" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%", width:"100%", border: "1px black ", padding:"0 12px 0 12px"}}>
                        <img src={imageSrcFreizeitUndFitness} alt="Slide" style={{height:"60%", width:"100%", filter:"none", opacity:"1", objectFit:"contain"}} />
                    </div>
                          <button className="buttonStyled" onClick={() => this.goToNext('freizeitUndFitness')} >
                                <i className="fas fa-arrow-right" />
                          </button>
                    </div>
              </div>
            </div>
         </div>
      </div>  */}

        </StyledCustomers>
    }
}




